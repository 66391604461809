import React from "react";
import { graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
const Glossary = ({ classes, data }) => {
  var entiredata = data.allStrapiEntities.edges;
  var image1 = data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
  var image2 = data.allStrapiImages.edges[1].node.content.childImageSharp.fluid;
  var Heading;
  var RightCards = [];
  var LeftCards = [];

  for (let i in entiredata) {
    let document = entiredata[parseInt(i)];
    let descArr = document.node.description.split(`\n`);
    let desc = descArr.map((line, k) => <span key={k}>{line}</span>);
    if (document.node.category != `glossary_header`) {
      if (document.node.category == `glossary_data_left`) {
        LeftCards.push(
          <Card
            blog
            className={classes.customCard}
            style={{ marginTop: `10px` }}
            key={`glossary-left-${i}`}
          >
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <div className={classes.description}>{desc}</div>
            </CardBody>
          </Card>
        );
      } else {
        RightCards.push(
          <Card
            blog
            className={classes.customCard}
            style={{ marginTop: `10px` }}
            key={`glossary-right-${i}`}
          >
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <div className={classes.description}>{desc}</div>
            </CardBody>
          </Card>
        );
      }
    } else {
      Heading = (
        <div key={`glossary-heading-${i}`}>
          {/* <h2 className={`${classes.title} `}>{document.node.title}</h2> */}
          <h2 className={classes.title} style={{ paddingTop: `10px` }}>
            {desc}
          </h2>
        </div>
      );
    }
  }
  return (
    <Layout image1={image1} image2={image2} small>
      <Helmet key="helmetTags">
        <title>Glossary | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/star-glossary"
          />
          <meta name="title" content="Glossary | StarHealth.in" />
          <meta name="twitter:title" content="Glossary | StarHealth.in" />
          <meta
            name="description"
            content="Every insurance term you need, in one place. Agent is appointed by the insurer to procure business for which he is given a license by the IRDAI."
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online, Health Insurance Specialist In India"
          />
          <meta property="og:title" content="Glossary | StarHealth.in" />
          <meta property="og:description" content="Every insurance term you need, in one place. Agent is appointed by the insurer to procure business for which he is given a license by the IRDAI." />
          <meta property="og:url" content="https://www.starhealth.in/star-glossary"/>
          <meta name="Glossary | StarHealth.in" />
          <meta name="twitter:description" content="Every insurance term you need, in one place. Agent is appointed by the insurer to procure business for which he is given a license by the IRDAI." />
          <meta property="twitter:url" content="https://www.starhealth.in/star-glossary" />
          <meta property="dcterms.date" content="2015-04-02T21:35+05:30" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: `100px` }}
      >
        <div className={classes.section}>
          <div
            className={classes.container}
            style={{ backgroundColor: `white` }}
          >
            <GridContainer className={classes.container}>
              <GridItem
                md={8}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              >
                {Heading}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                {LeftCards}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                {RightCards}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
Glossary.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(termsStyle)(Glossary);

export const termsQuery = graphql`
  query glossary {
    allStrapiEntities(
      filter: {
        category: {
          in: ["glossary_header", "glossary_data_left", "glossary_data_right"]
        }
      }
    ) {
      edges {
        node {
          id
          title
          description
          category
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "glossary" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
